
import React from 'react';
import './index.scss'

let month= ["January","February","March","April","May","June","July",
   "August","September","October","November","December"];

export default function DatePublication ({createdAt}) {
  let date = new Date(createdAt);
  let formatDate = `${date.getUTCDate()} ${month[date.getUTCMonth()]} ${date.getUTCFullYear()}`;

  return (
    <p className='DatePublication' children={formatDate} />
  )
}

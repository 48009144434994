import React from "react"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO"
import Title from "../../../components/Title"
import Desc from "../../../components/Desc"
import DatePublication from "../../../components/DatePublication"
import Button from "../../../components/Button"
import Spacer from "../../../components/Spacer"
import './index.scss'
import injectComponents from "../../../helpers/injectReactComponents";
import {replaceLinkComponent} from '../../../components/WebsiteLink';
import Img from 'gatsby-image'

const NewsPost = ({pageContext}) => {
   let {title, template, created_at, createdDate, hero, next} = pageContext;

    return (
      <Layout className='NewsPost'>
         <SEO title="Home" keywords={[]}/>

         <Img fluid={hero && hero.large.childImageSharp.fluid}
              className="Image"
              style={{ width: '100%' }}/>

         <div className="Content">
            <Title.Large>{title}</Title.Large>
            <DatePublication createdAt={createdDate || created_at}/>
            <Desc.Normal>
               {injectComponents(template, {...replaceLinkComponent})}
            </Desc.Normal>
            <div className={"footer"}>
               <Button.HistoryBack/>
               <Spacer/>
               {next.slug && <Button.Next to={`/news/posts/${next.slug}`}/>}
            </div>
         </div>
      </Layout>
   )
};


export default  NewsPost
